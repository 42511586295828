import React from 'react';
import { DNABox, DNADivider, DNAText, DNAButton } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { FolderORM, DocumentORM } from 'src/types/types'

import { useHistory } from 'src/router';

import { useAllFolders } from 'src/state/redux/selector/folder'
import { useAllDocuments, useAllDocumentsInstance } from 'src/state/redux/selector/document'
import folderFilters from 'src/state/redux/folder/query'
import docFilters from 'src/state/redux/document/query'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'

import DNAFolderPreviewHeader from 'src/components/DNA/FlatList/Headers/DNAFolderPreviewHeader'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { useCurrentUserORM } from 'src/state/redux/selector/user';
import { useAppSettings } from 'src/state/context/AppSettings';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import { useTenant } from 'src/state/redux/selector/tenant';
import ActiveUser from 'src/state/global/ActiveUser';
import QuickFiltersProvider from 'src/components/QuickFilters/QuickFiltersProvider';
import QuickFiltersHome from 'src/components/QuickFilters/QuickFiltersHome';
import { useCPMPageRoute } from 'src/components/ContentPreviewModalV2/Util/useCPMDedicatedUrl';

const gridWidth = {
  // (Card * No. Cols) + Space Between
  width: (272 * 2) + 16,
}

const bookmarksFilter = docFilters.merge(
  docFilters.filters.published,
  docFilters.filters.presentable,
  docFilters.filters.bookmarked,
  docFilters.sorts.bookmarkedDesc,
)

const folderFilter = folderFilters.merge(
  folderFilters.filters.rootFolder,
  folderFilters.filters.active,
  folderFilters.sorts.pinnedAsc,
)

const MSLHome: React.FC = () => {
  const history = useHistory();
  const documentActions = useDNADocumentActions()
  const userORM = useCurrentUserORM();
  const { deviceMode } = useAppSettings()
  const { goTo } = useScreenNav()
  const tenantORM = useTenant(ActiveUser.user!.tenantId);
  const quickFilters = tenantORM?.tenant.config.quickFilters;

  useCPMPageRoute()

  if (deviceMode === 'tablet') {
    goTo.FOLDERS();
  }

  const recentlyUpdatedFilter = docFilters.merge(
    docFilters.filters.published,
    docFilters.filters.presentable,
    docFilters.sorts.updatedAtDesc,
    docFilters.filters.defaultFilters(userORM?.meta.customFilterValues.defaultFilters || {}),
  )

  const folders = useAllFolders(folderFilter);
  const recentlyUpdatedDocuments = useAllDocuments(recentlyUpdatedFilter)
  const bookmarkedDocuments = useAllDocumentsInstance(bookmarksFilter)

  const onDocPress = (documentORM: DocumentORM) => {
    documentActions.preview(documentORM)()
  }

  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={{
          maxWidth: 1280,
          paddingHorizontal: 48,
          paddingVertical: 20,
        }}
      >
        <DNABox
          fill
          spacing="md"
          appearance="col"
          testID="page-container"
        >
          <DNABox appearance="row" spacing="sm" alignY="center">
            <DNAText
              testID="page-title"
              status="secondary"
              h5
            >
              Dashboard
            </DNAText>
            <DNAPopover placement="top" >
              <DNAPopover.Anchor>
                <DNAButton
                  appearance="ghost"
                  padding="none"
                  rounded="full"
                  status="gray"
                  size="md"
                  iconLeft="help-circle-outline"
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content offset={2}>
                <DNAText status="basic" style={{ width: 400 }}>
                  Internal files are hidden from this dashboard view to prevent visibility during external engagements.
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </DNABox>
          <DNADivider />
          {quickFilters &&
            <QuickFiltersProvider quickFilters={quickFilters}>
              <QuickFiltersHome />
            </QuickFiltersProvider>
          }
          {/* CONTENT */}
          {/* At a certain breakpoint, this should render into a column */}
          <DNABox
            fill
            childStyle={gridWidth}
            wrap="start"
            spacing="between"
          >
            {/* RECENTLY UPDATED */}
            <DNABox
              childFill={1}
              fill
              spacing="md"
              appearance="col"
              testID="dashboard-section"
            >
              <DNABox spacing="between" alignY="center">
                <DNAText h3>Recently Updated</DNAText>
                <DNAButton
                  testID="recently-updated-section-view-all"
                  appearance="ghostLink"
                  onPress={() => history.push('library')}
                >
                  View All
                </DNAButton>
              </DNABox>
              <DNAGrid
                items={recentlyUpdatedDocuments}
                onPress={onDocPress}
                limit={4}
                emptyVariant={EmptyVariant.DocumentGridRecentlyUpdated}
                spacing="between"
              />
            </DNABox>

            {/* BOOKMARKS UPDATED */}
            <DNABox
              childFill={1}
              fill
              spacing="md"
              appearance="col"
              testID="dashboard-section"
            >
              <DNABox spacing="between" alignY="center">
                <DNAText h3>Bookmarks</DNAText>
                <DNAButton
                  testID="bookmarks-section-view-all"
                  appearance="ghostLink"
                  onPress={() => history.push('bookmarks')}
                >
                  View All
                </DNAButton>
              </DNABox>
              <DNAGrid
                items={bookmarkedDocuments}
                onPress={onDocPress}
                limit={4}
                emptyVariant={EmptyVariant.DocumentGridBookmark}
                spacing="between"
              />
            </DNABox>
          </DNABox>

          {/* FOLDERS LIST */}
          <DNABox testID="dashboard-section" style={{ borderRadius: 4, overflow: 'hidden' }}>
            <DNAFlatList<FolderORM>
              items={folders}
              limit={5}
              onPress={(folderORM) => history.push(`folders/${folderORM.model.id}`)}
              emptyVariant={EmptyVariant.FolderListCreate}
              emptyWithHeader
            >
              <DNAFolderPreviewHeader />
            </DNAFlatList>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default MSLHome
