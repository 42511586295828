import React, {
  ReactElement,
  useState,
  useMemo,
  useRef,
  useContext,
  createContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Icon, luxColors, DNABox, DNAButton, DNASlider, useDisableSwipe, DNAText } from '@alucio/lux-ui';
import { OverflowMenu, Input } from '@ui-kitten/components';
import InputComponent from '../../Publishers/InputComponent';
import { useHistory, useLocation } from 'react-router';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { useAppSettings } from 'src/state/context/AppSettings';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useIsPublisherView from 'src/hooks/useIsPublisherView/useIsPublisherView';
import debounce from 'lodash/debounce';
import ROUTES from 'src/router/routeDef';
import qs from 'qs';
import DNALibrary from 'src/screens/MyLibrary/DNALibrary'
import SearchInputResultsDesktop from './SearchInputResultsDesktop';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';

import DocumentSearchProvider, { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2';
import { DocumentSearchProxyProvider } from 'src/hooks/useDocumentSearchV2.proxy'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { useOnlineStatus } from '@alucio/core';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { isIOS } from 'react-device-detect';

export interface SearchInputProps {
  searchText: string,
}

export const SearchInputContext = createContext<SearchInputProps>({ searchText: '' })

const styles = StyleSheet.create({
  icon: {
    color: luxColors.disabled.primary,
    height: 25,
    width: 25,
  },
  searchBarStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: luxColors.disabled.secondary,
    borderRadius: 4,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  searchBarWrapper: {
    marginLeft: 30,
    marginTop: 3,
    flex: 1,
  },
  popover: {
    backgroundColor: colors['color-black'],
  },
  popoverContent: {
    padding: 8,
  },
  popoverText: {
    color: colors['color-text-white'],
  },
  popoverBodyHeader: {
    color: colors['color-text-white'],
    textDecorationLine: 'underline',
  },
  popoverCloseBtn: {
    backgroundColor: colors['color-gray-600'],
    borderRadius: 4,
  },
  popoverCloseIcon: {
    color: colors['color-text-white'],
    width: 35,
    height: 35,
  },
  italic: {
    color: colors['color-text-white'],
    fontStyle: 'italic',
  },
});

const ICONS = {
  CLOSE: 'close',
  MARGNIFY: 'magnify',
  LIGHT: 'help-circle-outline',
};

const useSearchInput = () => {
  const isPublisherView = useIsPublisherView();
  const { currentSearchString, setCurrentSearchString } = useSearchTextContextProvider();
  const { pathname, search } = useLocation();
  const qsParam = qs.parse(search, { ignoreQueryPrefix: true })
  const isPublisherSearchView = pathname.toLowerCase() === ROUTES.SEARCH_RESULTS_PUBLISHER.PATH
  const queryParamSearchString = typeof qsParam.searchText === 'string' ? qsParam.searchText : ''
  const [searchText, setSearchText] = useState<string>(
    isPublisherView
      ? queryParamSearchString
      : currentSearchString,
  )
  const canPerformSearch = useCanPerformSearch();
  const [displaySearchResults, setDisplaySearchResults] = useState<boolean>(false);
  const inputRef = useRef<Input>(null)
  const history = useHistory();

  useEffect(() => {
    setSearchText(currentSearchString)
  }, [currentSearchString])

  function onChangeText(text: string): void {
    const canSearch = canPerformSearch(text.trim());
    setSearchText(text);
    setCurrentSearchString(text);

    if (!isPublisherView) {
      if (!displaySearchResults && canSearch) {
        setDisplaySearchResults(true);
      } else if (displaySearchResults && !canSearch) {
        setDisplaySearchResults(false);
      }
    }
    else if (!isPublisherSearchView && canSearch) {
      history.push(`${ROUTES.SEARCH_RESULTS_PUBLISHER.PATH}?searchText=${text}`);
    }
  }

  function clearText(): void {
    onChangeText('');
    inputRef.current?.focus()
  }

  function toggleSearchResultComponent(open: boolean): void {
    setDisplaySearchResults(open);
  }

  function resetSearch(): void {
    setSearchText('')
    setDisplaySearchResults(false)
  }

  return {
    displaySearchResults,
    onChangeText,
    clearText,
    toggleSearchResultComponent,
    searchText,
    setSearchText,
    resetSearch,
    setDisplaySearchResults,
    canPerformSearch,
    inputRef,
    isPublisherView,
  }
}

const KendraSearchTipPopoverBody = (): ReactElement => {
  return (
    <DNABox appearance="col" spacing="md">
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Exact match</DNAText>
        <DNAText style={styles.popoverText}>Put a word or phrase inside quotes</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: "phase 3 data" </DNAText>
          returns files that contain those exact words
        </DNAText>
      </DNABox>
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Combine searches</DNAText>
        <DNAText style={styles.popoverText}>Put "OR" between each search query</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: jak OR stat </DNAText>
          returns results that contain the terms "jak" or "stat," or both</DNAText>
      </DNABox>
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Exclude terms</DNAText>
        <DNAText style={styles.popoverText}>Put "NOT" before a term to exclude it</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: cancer NOT lung </DNAText>
          returns all cancer results except for lung cancer</DNAText>
      </DNABox>
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Wildcard search</DNAText>
        <DNAText style={styles.popoverText}>Use an asterisk to complete a search term</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: canc* </DNAText>
          return files with the words cancer, cancers, cancerous, etc.</DNAText>
      </DNABox>
    </DNABox>
  )
}

const OpenSearchTipPopoverBody = (): ReactElement => {
  return (
    <DNABox appearance="col" spacing="md">
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Exact match</DNAText>
        <DNAText style={styles.popoverText}>Put a word or phrase inside quotes</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: "phase 3 data" </DNAText>
          returns files that contain those exact words
        </DNAText>
      </DNABox>
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Combine searches</DNAText>
        <DNAText style={styles.popoverText}>Put "+" between each search query</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: jak +stat </DNAText>
          returns results that contain the terms "jak" + "stat," or both</DNAText>
      </DNABox>
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Exclude terms</DNAText>
        <DNAText style={styles.popoverText}>Put "-" before a term to exclude it</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: cancer -lung </DNAText>
          returns all cancer results except for lung cancer</DNAText>
      </DNABox>
      <DNABox appearance="col" spacing="xs">
        <DNAText style={styles.popoverBodyHeader} h5>Wildcard search</DNAText>
        <DNAText style={styles.popoverText}>Use an asterisk to complete a search term</DNAText>
        <DNAText style={styles.popoverText}>
          <DNAText style={styles.italic}>Example: canc** </DNAText>
          return files with the words cancer, cancers, cancerous, etc.</DNAText>
      </DNABox>
    </DNABox>
  )
}

const SearchTipPopoverBody = (): ReactElement => {
  const enableOpenSearch = useFeatureFlags('enableOpenSearch')
  return enableOpenSearch
    ? <OpenSearchTipPopoverBody />
    : <KendraSearchTipPopoverBody/>
}

const SearchInputDeskTop = () => {
  const history = useHistory();
  const isOnline = useOnlineStatus()
  const route = useCurrentPage()
  const { isOpen } = useSelector((state: RootState) => state.contentPreviewModal)
  const enableKendra = useFeatureFlags('enableKendra')

  const {
    onChangeText,
    displaySearchResults,
    clearText,
    toggleSearchResultComponent,
    searchText,
    setSearchText,
    setDisplaySearchResults,
    canPerformSearch,
    inputRef,
    isPublisherView,
  } = useSearchInput();
  const [searchTipPopover, setSearchTipPopover] = useState<boolean>(false)

  const contexValues = useDocumentSearchV2Context()

  useEffect(() => {
    return () => {
      setSearchTipPopover(false)
    }
  }, [route, isOpen])

  function moveToResultsPage(): void {
    if (canPerformSearch(searchText.trim()) && !isPublisherView) {
      setDisplaySearchResults(false);
      // / and % are not allowed in the search text because as we are replacing the url with the search text that is entered
      // so we need to remove these characters from the search text to avoid any issues
      const regex = /[/|%]/g;

      history.push(`/search/${searchText.replace(regex, '')}`);

      if (searchText.match(regex)) {
        setSearchText(searchText.replace(regex, ''));
      }

      analytics?.track('SEARCH_INPUT', {
        action: 'INPUT',
        category: 'SEARCH',
        searchText,
      });
    }
  }

  function handlePressSearchTip(): void {
    // if the popover is closed do not send the events
    if (!searchTipPopover) {
      analytics.track('SEARCH_TIPS', {
        action: 'OPEN',
        category: 'SEARCH',
      })
    }
    setSearchTipPopover(!searchTipPopover)
  }

  function renderLeftIcon(): ReactElement {
    return (
      <TouchableOpacity
        onPress={ moveToResultsPage}
        testID="header-search-input-results"
      >
        <Icon style={styles.icon} name={ICONS.MARGNIFY} />
      </TouchableOpacity>
    );
  }

  function renderRightIcon(): ReactElement {
    return (
      <DNABox spacing="xs">
        {!searchText
          ? <DNABox />
          : <TouchableOpacity
              onPress={clearText}
              testID="header-search-input-clear"
          >
            <Icon style={styles.icon} name={ICONS.CLOSE} />
          </TouchableOpacity>
        }
        {(isOnline && enableKendra) &&
          <DNAPopover
            placement="right"
            visible={searchTipPopover}
            style={styles.popover}
            interactive={true}
          >
            <DNAPopover.Anchor>
              <TouchableOpacity onPress={handlePressSearchTip}>
                <Icon style={styles.icon} name={ICONS.LIGHT} />
              </TouchableOpacity>
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNABox appearance="col" style={styles.popoverContent} spacing="md">
                <DNABox appearance="row" spacing="between" alignY="center">
                  <DNAText style={styles.popoverText} h4 >Search Tips</DNAText>
                  <TouchableOpacity
                    style={styles.popoverCloseBtn}
                    onPress={() => setSearchTipPopover(!searchTipPopover)}
                  >
                    <Icon style={styles.popoverCloseIcon} name={ICONS.CLOSE} />
                  </TouchableOpacity>
                </DNABox>
                <SearchTipPopoverBody />
              </DNABox>
            </DNAPopover.Content>
          </DNAPopover>
        }
      </DNABox>
    )
  }

  function renderSearchInput(): ReactElement {
    return (
      <DNABox style={styles.searchBarWrapper}>
        <InputComponent
          ref={inputRef}
          testID="header-search-input"
          value={searchText}
          hideLabel={true}
          onChangeText={onChangeText}
          title="Search for files"
          removeMarginPadding={true}
          inputStyle={styles.searchBarStyle}
          getLeftIconFunction={() => renderLeftIcon()}
          getRightIconFunction={() => renderRightIcon()}
          onSubmitEditing={moveToResultsPage}
          autoCompleteType="off"
        />
      </DNABox>
    );
  }

  function onBackdropPressHandler() {
    toggleSearchResultComponent(false)
  }

  function onSelectItemHandler() {
    setDisplaySearchResults(false);
    clearText()
  }

  return (
    <OverflowMenu
      anchor={renderSearchInput}
      visible={displaySearchResults && !isPublisherView}
      placement="bottom start"
      fullWidth={true}
      scrollEnabled={true}
      onBackdropPress={onBackdropPressHandler}
      style={{ maxHeight: '85vh' }}
    >
      <DocumentSearchProxyProvider value={contexValues}>
        <SearchInputResultsDesktop
          searchText={searchText}
          moveToResultsPage={moveToResultsPage}
          onSelectItem={onSelectItemHandler}
        />
      </DocumentSearchProxyProvider>
    </OverflowMenu>
  );
};

const SearchInputTablet = () => {
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const [searchTipPopover, setSearchTipPopover] = useState<boolean>(false);
  const { onChangeText, searchText, resetSearch, displaySearchResults } = useSearchInput();
  const isOnline = useOnlineStatus();
  const enableKendra = useFeatureFlags('enableKendra');

  useDisableSwipe()

  const SearchInputContextValue: SearchInputProps = {
    searchText: searchText,
  };

  // Memoize to prevent re-render on exit animation
  // [TODO] Consider memo'ing
  // (pure functioning the List itself since it's performance heavy in multiple places)
  const SearchResults = useMemo(
    () => (
      <SearchInputContext.Provider value={SearchInputContextValue}>
        <DNALibrary
          /** NOTE: Because we are using this component inside a modal and not as a route, we have
           * to resort to prop drilling to get the filters side bar to disable the quick filters 😥 */
          disableQuickFilters
          initUserDefaultFilters={false}
          toggleSearchMessage={!displaySearchResults}
        />
      </SearchInputContext.Provider >
    ),
    [searchText, displaySearchResults],
  )

  function handlePressSearchTip(): void {
    // if the popover is closed do not send the events
    if (!searchTipPopover) {
      analytics.track('SEARCH_TIPS', {
        action: 'OPEN',
        category: 'SEARCH',
      })
    }
    setSearchTipPopover(!searchTipPopover)
  }

  function renderRightIcon(): ReactElement {
    return (
      <>
        {(isOnline && enableKendra) &&
          <DNAPopover
            placement="bottom"
            visible={searchTipPopover}
            style={styles.popover}
            interactive={true}
            onBackdropPress={() => setSearchTipPopover(!searchTipPopover)}
          >
            <DNAPopover.Anchor>
              <TouchableOpacity onPress={handlePressSearchTip}>
                <Icon style={styles.icon} name={ICONS.LIGHT} />
              </TouchableOpacity>
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNABox appearance="col" style={styles.popoverContent} spacing="md">
                <DNABox appearance="row" spacing="between" alignY="center">
                  <DNAText style={styles.popoverText} h4 >Search Tips</DNAText>
                  <TouchableOpacity
                    style={styles.popoverCloseBtn}
                    onPress={() => setSearchTipPopover(!searchTipPopover)}
                  >
                    <Icon style={styles.popoverCloseIcon} name={ICONS.CLOSE} />
                  </TouchableOpacity>
                </DNABox>
                <SearchTipPopoverBody />
              </DNABox>
            </DNAPopover.Content>
          </DNAPopover>
        }
      </>
    )
  }

  return (
    <>
      <DNAButton
        appearance="ghostLink"
        status="dark"
        size="xl"
        padding="none"
        iconLeft="magnify"
        onPress={() => setShowSlider(p => !p)}
      />

      <DNASlider
        visible={showSlider}
        invertAnimation={isIOS}
        setVisible={setShowSlider}
        onInvisible={resetSearch}
      >
        <DNABox style={{ backgroundColor: 'white' }} appearance="col" fill>
          {/* Search header */}
          <DNABox
            appearance="row"
            alignY="center"
            style={{ backgroundColor: colors['color-brand1-800'], height: 72 }}
          >
            <DNABox fill style={{ height: 48 }}>
              <DNABox testID="header-search-input" alignY="center" fill style={{ marginLeft: 16, marginTop: 4 }}>
                <InputComponent
                  hideLabel={true}
                  removeMarginPadding={true}
                  title="Search all files"
                  onChangeText={debounce(onChangeText, 500)}
                  titleColor={luxColors.contentText.tertiary}
                  inputStyle={{ borderRadius: 8 }}
                  getRightIconFunction={() => renderRightIcon()}
                  size={LuxSizeEnum.md}
                  autoFocus
                />
              </DNABox>
              <DNABox style={{ marginHorizontal: 6 }} alignY="center">
                <DNAButton status="secondary" onPress={() => setShowSlider(false)}>
                  Cancel
                </DNAButton>
              </DNABox>
            </DNABox>
          </DNABox>
          {/* Search Results */}
          <DNABox fill appearance="col">
            {SearchResults}
          </DNABox>
        </DNABox>
      </DNASlider>
    </>
  )
}

const SearchTextContext = createContext<{
  currentSearchString: string;
  setCurrentSearchString:(text: string) => void;
    }
    >(null!)

export const SearchTextContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { pathname } = useLocation();
  const defaultMSLSearchString = pathname.toLowerCase() === ROUTES.SEARCH_RESULTS_PUBLISHER.SEARCH
  const [currentSearchString, setCurrentSearch] = useState(defaultMSLSearchString ? pathname.split('/')[2] : '')

  const setCurrentSearchString = (text: string) => setCurrentSearch(text)

  const context = {
    currentSearchString,
    setCurrentSearchString,
  }

  return (
    <SearchTextContext.Provider value={context}>
      {props.children}
    </SearchTextContext.Provider>
  )
}

export const useSearchTextContextProvider = () => useContext(SearchTextContext)

const SearchInput = () => {
  const { deviceMode } = useAppSettings()

  return (
    <DocumentSearchProvider mode="HEADER">
      {
      deviceMode === 'desktop'
        ? <SearchInputDeskTop />
        : <SearchInputTablet />
      }
    </DocumentSearchProvider>

  )
}

SearchInput.displayName = 'SearchInput';

export default SearchInput;
