import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Pressable, ScrollView } from 'react-native';
import {
  DocumentORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
  PageGroupORM,
  Presentable,
} from 'src/types/types'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import documentQuery, { filters } from 'src/state/redux/document/query';

import { ContentORMs } from 'src/components/DNA/types'
import { DNABox, DNAIcon, Iffy, DNAText, DNASelect, IndexPath, DNACard } from '@alucio/lux-ui';

import {
  useDNAFolderNav,
  withDNAFolderNav,
} from 'src/components/DNA/Folder/Nav/DNAFolderNav'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { SLIDE_ROLL_VARIANT } from 'src/components/DNA/SlideRoll/StyleSetting';
import { DisplayMode, DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import DNASlideRoll from 'src/components/DNA/SlideRoll/DNASlideRoll';
import { Options } from '../../useMyContentPanelSharedResources';
import {
  isCustomDeckORM,
  isDocumentORM,
  isDocumentVersionORM,
  isFolderItemORM,
  isFolderORM,
  isPageGroupORM,
} from 'src/types/typeguards'
import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { useAppSettings } from 'src/state/context/AppSettings';
import { getRootFolderORM, useIsSharedFoldersStatus } from 'src/state/redux/selector/folder';
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder';
import { useDispatch } from 'src/state/redux';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { SliceStatus } from 'src/state/redux/slice/common';
import { useDocumentSearchV2 } from 'src/hooks/useDocumentSearchV2';
import ListSkeleton from 'src/components/ListSkeleton/ListSkeleton';
import { getPresentable } from 'src/state/context/ContentProvider/helper';

interface TabConfigProps {
  items: FolderItemORM[] | DocumentORM[]
  label: string
}
type TabConfigs = Record<Options, TabConfigProps>
type EmptyVariants = Record<Options, EmptyVariant>

interface ContentProps {
  localSearchText: string,
  selectedTab: Options,
  setSelectedDocumentVersion: (doc: DocumentVersionORM | undefined, folderItemORM?: FolderItemORM) => void,
  setSelectedPage: (index: number | undefined, folderItemORM?: FolderItemORM | PageGroupORM) => void,
  displayMode: DisplayMode,
  searchText?: string,
  selectedDocumentVersion?: DocumentVersionORM,
  sessionId?: string,
  showPreview?: boolean,
}

const BOOKMARKED_DOCS_QUERY = documentQuery.merge(
  documentQuery.filters.published,
  documentQuery.filters.bookmarked,
  documentQuery.sorts.bookmarkedDesc,
)

const ALL_DOCS_QUERY = documentQuery.merge(
  documentQuery.sorts.updatedAtDesc,
  documentQuery.sorts.titleAsc,
  documentQuery.filters.published,
  documentQuery.sorts.bookmarkedDesc,
)

const GroupsSelector: React.FC<{
  selectedIndex: IndexPath,
  groupOptions: PageGroupORM[] | undefined,
  onGroupSelect: (index: IndexPath | IndexPath[]) => void,
  selectedValue: () => string | undefined,
}> = (props) => {
  const {
    selectedIndex,
    groupOptions,
    onGroupSelect,
    selectedValue,
  } = props

  useEffect(() => {
    return () => {
      onGroupSelect(new IndexPath(0))
    }
  }, [])

  if (!groupOptions) return null

  return (
    <DNABox alignY="center" childFill>
      <DNASelect
        style={{ width: 727, marginBottom: 10 }}
        selectedIndex={selectedIndex}
        value={selectedValue()}
        onSelect={onGroupSelect}
      >
        {
          [
            <DNASelect.Item key="allSlides" title="All slides" />,
            ...groupOptions?.map(group => {
              return (
                <DNASelect.Item
                  key={group.model.id}
                  title={group.model.name}
                />
              )
            })]
        }
      </DNASelect>
    </DNABox>
  )
}

const ContentPresenterMode: React.FC<ContentProps> = (props) => {
  const {
    localSearchText,
    selectedTab,
    displayMode,
    searchText = '',
    selectedDocumentVersion,
    setSelectedDocumentVersion,
  } = props

  const dispatch = useDispatch()
  const { setCurrentTab } = useMeetingsState()

  const {
    currentItems,
    pushFolder,
    popToFolder,
    folderStack,
    setDisplaySharedFolders,
    isCurrentlyNested,
  } = useDNAFolderNav()

  const initialLoad = useRef<boolean>(true)
  const [folderItemORM, setFolderItemORM] = useState<FolderItemORM | undefined>();
  /** TODO: Update this to utilize a dynamic reference to the current document rather than manually setting the selected index (See selectedIndex in DNASlideRoll)  */
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0))
  const currentPageGroup = useRef<PageGroupORM | DocumentVersionORM | undefined>(selectedDocumentVersion)
  const bookmarkedDocs = useAllDocumentsInstance(BOOKMARKED_DOCS_QUERY)
  const documents = useAllDocumentsInstance(ALL_DOCS_QUERY)
  const {
    documentORMSearchResults: resultsFromSearch,
    isLoadingSearch,
  } = useDocumentSearchV2(searchText, filters.published);

  const activeItems = currentItems.filter(
    item => (
      isDocumentVersionORM(item.relations.itemORM)
        ? item.relations.itemORM.model.status !== 'NOT_PUBLISHED'
        : true
    ),
  )

  const { meetingORM } = useMeetingsState();
  const { isOnline } = useAppSettings()
  const { activePresentation } = useContent()
  const docHasGroups = selectedDocumentVersion?.relations.pageGroups.length
  // getting the external dependencies of a shared folder and shared with me folder use the same slice so it shares the same status
  // if we want to have a separate loading status for My folders Shared folder/Shared with me, we will have to refactor the sharedFolder.ts slice
  const isLoading = useIsSharedFoldersStatus(SliceStatus.PENDING);

  const title = selectedDocumentVersion?.model.title || folderItemORM?.model.customTitle;
  const customDeckORM =
    (
      isCustomDeckORM(folderItemORM?.relations?.itemORM) &&
      folderItemORM?.relations?.itemORM
    ) || undefined

  const presentable: Presentable | undefined = useMemo(() => {
    if (selectedDocumentVersion || customDeckORM) {
      if (currentPageGroup.current) {
        return getPresentable(currentPageGroup.current);
      } else if (customDeckORM && isFolderItemORM(folderItemORM)) {
        return getPresentable(folderItemORM);
      } else {
        return getPresentable(selectedDocumentVersion);
      }
    }
  }, [selectedDocumentVersion, customDeckORM, selectedIndex]);

  const tabConfigs: TabConfigs = {
    [Options.FOLDERS]: {
      items: activeItems,
      label: 'My Folders',
    },
    [Options.SHARED_WITH_ME]: {
      items: currentItems,
      label: 'Shared With Me',
    },
    [Options.BOOKMARKS]: {
      items: bookmarkedDocs,
      label: 'Bookmarks',
    },
    [Options.LIBRARY]: {
      items: documents,
      label: 'Library',
    },
    // "Ghost tab". It works as a tab but is not shown as an option to be selected.
    // Will be applied upon a valid search text input value.
    [Options.SEARCH_RESULTS]: {
      items: resultsFromSearch,
      label: 'Search results',
    },
  }

  const groups = selectedDocumentVersion?.relations.pageGroups &&
  [...selectedDocumentVersion.relations.pageGroups]
    .sort((a, b) => a.model.name.localeCompare(b.model.name))

  const { items, label } = tabConfigs[selectedTab]

  const isVirtualMeeting = meetingORM?.model.type === 'VIRTUAL'

  const onGroupSelect = (index: IndexPath | IndexPath[]) => {
    if (!groups) {
      setSelectedIndex(new IndexPath(0))
      currentPageGroup.current = selectedDocumentVersion
      return
    }

    if (index instanceof IndexPath) {
      const { row } = index
      if (row === 0) {
        setSelectedIndex(index)
        currentPageGroup.current = selectedDocumentVersion
      } else {
        setSelectedIndex(index)
        const group = groups[row - 1]
        currentPageGroup.current = group

        // ANALYTIC TRACKING: group shown in 3PC
        analytics?.track('PRESENT', {
          pageGroupId: group.model.id,
          meetingId: meetingORM?.model.id,
        })
      }
    }
  }

  const selectedValue = () => {
    if (selectedIndex.row === 0) {
      return 'All slides'
    } else if (groups && groups.length) {
      return groups[selectedIndex.row - 1].model.name
    }
  }

  const onItemPress = (itemORM: ContentORMs) => {
    setSelectedIndex(new IndexPath(0))
    const isDocumentVersionInFolder =
      isFolderItemORM(itemORM) &&
      isDocumentVersionORM(itemORM.relations.itemORM) &&
      itemORM.relations.itemORM.relations.documentORM.meta.permissions.MSLPresent

    // IF IT'S NOT A DOCUMENT VERSION FROM A FOLDER, WE WANT TO CLEAN THE STATE
    if (!isDocumentVersionInFolder) {
      setFolderItemORM(undefined)
      // clean group selection and show all slides
      onGroupSelect(new IndexPath(0))
    }

    if (isFolderORM(itemORM)) {
      const rootFolder = itemORM.relations.parentFolderORM
        ? getRootFolderORM(itemORM.relations.parentFolderORM)
        : itemORM

      rootFolder &&
        itemORM.meta.hasExternalDependencies &&
        dispatch(sharedFolderActions.getFolderExternalDependencies({
          folderId: itemORM.model.id,
          parentFolderId: rootFolder.model.id,
        }))

      pushFolder(itemORM)
    } else if (isFolderItemORM(itemORM)) {
      if (
        isDocumentVersionORM(itemORM.relations.itemORM) &&
        itemORM.relations.itemORM.relations.documentORM.meta.permissions.MSLPresent
      ) {
        setFolderItemORM(itemORM);
        setSelectedDocumentVersion(itemORM.relations.itemORM);
      } else if (isFolderORM(itemORM.relations.itemORM)) {
        pushFolder(itemORM.relations.itemORM)
      } else if (isCustomDeckORM(itemORM.relations.itemORM)) {
        setFolderItemORM(itemORM);
        setSelectedDocumentVersion(undefined);
      }
    } else if (isDocumentORM(itemORM) && itemORM.meta.permissions.MSLPresent) {
      setSelectedDocumentVersion(itemORM.relations.version.latestPublishedDocumentVersionORM)
      onGroupSelect(new IndexPath(0))
    }
  }

  /** TODO: This seems like something we can make shared */
  const emptyVariants: EmptyVariants = {
    [Options.FOLDERS]: isLoading
      ? EmptyVariant.FolderListLoadingDark
      : isCurrentlyNested
        ? EmptyVariant.FileListPresentEmpty
        : EmptyVariant.FolderListPresentEmpty,
    [Options.SHARED_WITH_ME]: isOnline
      ? isLoading
        ? EmptyVariant.FolderListLoadingDark
        : isCurrentlyNested
          ? EmptyVariant.FileListPresentEmpty
          : EmptyVariant.FolderSharedPresentEmpty
      : EmptyVariant.FolderListSharedOffline,
    [Options.BOOKMARKS]: EmptyVariant.FolderBookmarkPresentEmpty,
    [Options.LIBRARY]: EmptyVariant.DocumentListEmpty,
    [Options.SEARCH_RESULTS]: EmptyVariant.MeetingsDocumentResultsEmpty,
  }

  const emptyVariant = emptyVariants[selectedTab]

  const clearNavStack = () => {
    popToFolder();
    setFolderItemORM(undefined);
    setSelectedDocumentVersion(undefined);
  }

  const presentPage = (index: number) => {
    if (!selectedDocumentVersion && !isCustomDeckORM(folderItemORM?.relations.itemORM)) {
      console.error('Could not find selected document to present')
      return;
    } else if (isPageGroupORM(currentPageGroup.current)) {
      props.setSelectedPage(index, currentPageGroup.current)
    } else {
      props.setSelectedPage(index, folderItemORM);
    }
    isVirtualMeeting && setCurrentTab('PRESENTING')

    popToFolder(folderStack.at(-1))
    setFolderItemORM(undefined);
    setSelectedDocumentVersion(undefined)
  }

  const isSearchLoading = isLoadingSearch && selectedTab === Options.SEARCH_RESULTS

  const searchResultsText = selectedTab !== Options.SEARCH_RESULTS
    ? `${items.length} item(s)`
    : `Search results for "${localSearchText}"${isSearchLoading ? '' : ` | ${items.length} result(s)`}`

  // Handle tab switch behavior
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false

      if (selectedTab === Options.FOLDERS) {
        const { orm } = { ...activePresentation?.presentable }

        /** If the active presentation is from my folders, iterate over
       * relations to create folder stack and locate top level folder */
        if (isFolderItemORM(orm)) {
          const initialFolderStack: FolderORM[] = []

          let currentParentORM: FolderORM | null | undefined = orm.relations.parentORM
          while (isFolderORM(currentParentORM)) {
            initialFolderStack.unshift(currentParentORM)
            currentParentORM = currentParentORM.relations.parentFolderORM
          }

          initialFolderStack.forEach(folderORM => pushFolder(folderORM))
        }
      } else {
        clearNavStack()
      }
    } else {
      clearNavStack()
    }
  }, [selectedTab]);

  // Disable display toggle if document is selected (slide roll view)
  useEffect(() => {
    displayMode.setEnableDisplayMode(!selectedDocumentVersion)
  }, [selectedDocumentVersion])

  useEffect(() => {
    /**
     * Suggest refactoring this to rely solely on the query param in DNAFolderNav.
     * This would require a minor rework of the shared folder tab in the My Folders
     * section as well.
     * */
    setDisplaySharedFolders(selectedTab === Options.SHARED_WITH_ME)
  }, [selectedTab])

  const { thumbnailDimensions } = useThumbnailSize(['lg'])

  const showTabLabel = ((selectedDocumentVersion || customDeckORM) && selectedTab === Options.SEARCH_RESULTS) ||
  selectedTab !== Options.SEARCH_RESULTS;
  const showFolderItems = !selectedDocumentVersion && !customDeckORM && !isSearchLoading

  return (
    <DNABox fill>
      {
        <DNABox appearance="col" fill style={{ marginHorizontal: 24, marginTop: 16 }}>
          {/* BREAD CRUMBS */}
          <DNABox alignY="center" style={{ marginBottom: 16 }}>
            <Iffy is={showTabLabel}>
              <Pressable onPress={clearNavStack}>
                <DNAText testID="browse-content-bread-crumbs" numberOfLines={1} status="subtle">
                  {label}
                </DNAText>
              </Pressable>
            </Iffy>

            {/* Render the Folder Stack as Breadcrumbs */}
            {folderStack.map(
              folderORM => (
                <DNABox shrink alignY="center" key={folderORM.model.id}>
                  <DNAIcon.Styled
                    name="chevron-right"
                    appearance="ghost"
                    status="primary"
                    style={{ marginTop: 2 }}
                  />
                  <Pressable
                    style={{ flexShrink: 1 }}
                    onPress={() => {
                      popToFolder(folderORM);
                      setFolderItemORM(undefined);
                      setSelectedDocumentVersion(undefined)
                    }}
                  >
                    <DNAText numberOfLines={1} status="subtle">
                      {folderORM.model.name}
                    </DNAText>
                  </Pressable>
                </DNABox>
              ),
            )}

            <Iffy is={(selectedDocumentVersion || customDeckORM) && isOnline}>
              <DNABox shrink alignY="center">
                <DNAIcon.Styled
                  name="chevron-right"
                  appearance="ghost"
                  status="primary"
                  style={{ marginTop: 2 }}
                />
                <Pressable style={{ flexShrink: 1 }}>
                  <DNAText numberOfLines={1} status="subtle">
                    {title}
                  </DNAText>
                </Pressable>
              </DNABox>
            </Iffy>

            {/* # of search results header */}
            <Iffy is={selectedTab !== Options.FOLDERS && !selectedDocumentVersion}>
              <DNAText b2 status="subtle" style={{ marginLeft: 4 }}>
                {searchResultsText}
              </DNAText>
            </Iffy>
          </DNABox>
          {/* GROUP SELECTOR */}
          {docHasGroups &&
            <GroupsSelector
              selectedIndex={selectedIndex}
              groupOptions={groups}
              onGroupSelect={onGroupSelect}
              selectedValue={selectedValue}
            />
          }
          {/* TODO: Offline states for the shared with me tab need to be defined and impelemented. BEAC-2823
            See: https://www.figma.com/file/2gMCgBkvFKk9rlqXvYxk6w/Viewer?node-id=13353%3A219223
          */}
          {/* LOADING STATE */}
          <Iffy is={isSearchLoading}>
            <DNACard
              appearance="meetings"
              style={{ backgroundColor : 'rgba(255, 255, 255, 0.06)' }}
            >
              <ListSkeleton numberOfItems={5} variant="dark" />
            </DNACard>
          </Iffy>
          {/* FLATLIST */}
          <Iffy is={showFolderItems && displayMode.displayMode === DisplayModes.list}>
            <DNAFlatList<(DocumentORM | DocumentVersionORM | FolderORM) >
              items={items}
              variant="meetings"
              appearance
              emptyVariant={emptyVariant}
              onPress={onItemPress}
              useEmptyContainer={false}
              isSearch={selectedTab === Options.SEARCH_RESULTS}
            />
          </Iffy>
          {/* GRID */}
          <Iffy is={showFolderItems && displayMode.displayMode === DisplayModes.grid}>
            <DNABox fill appearance="col" as={ScrollView}>
              <DNAGrid<(DocumentORM | DocumentVersionORM | FolderORM) >
                items={items}
                /** TODO: update in BEAC-2822 */
                variant="virtual"
                emptyVariant={emptyVariant}
                onPress={onItemPress}
                useEmptyContainer={false}
              />
            </DNABox>
          </Iffy>
          {/* SLIDEROLL */}
          {
            presentable &&
              <DNASlideRoll
                activeSlide={presentable.presentablePages[0]!}
                horizontal={false}
                ignoreSelected
                isGridView
                itemHeight={thumbnailDimensions.height}
                itemWidth={thumbnailDimensions.width}
                onSelect={presentPage}
                presentable={presentable}
                showPreview={isVirtualMeeting}
                variant={SLIDE_ROLL_VARIANT.MEETINGS}
              />
          }
        </DNABox>
      }
    </DNABox>

  );
}

export default withDNAFolderNav(ContentPresenterMode)
