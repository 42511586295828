import {
  ControlType,
  CrmSyncStatus,
  CustomFieldDefinition,
  CustomFieldValueDefinition,
  DateRestriction,
  FieldDataType,
  FieldPosition,
  ObjectRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { FormSettings } from '../CRMIndexedDBTypes';
import { v4 as uuid } from 'uuid';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { MeetingORM } from 'src/types/orms';
import { isArrayOfObjects, ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { getCallDetailsProductValues, VEEVA_FIELDS_PLACEHOLDER } from './VeevaMarkerHandler';
import { orderArray } from 'src/utils/arrayHelpers';
import { OBJECT_RECORD_STATUS } from '@alucio/aws-beacon-amplify/lib/API';
import { CRMIntegrationSession, CRMUtil } from 'src/state/machines/CRM/util';
import * as logger from 'src/utils/logger'

export const TABLES = {
  CALL: 'Call2_vod__c',
  CALL_DETAIL: 'Call2_Detail_vod__c',
  CALL_DISCUSSION: 'Call2_Discussion_vod__c',
  MEDICAL_DISCUSSION: 'Medical_Discussion_vod__c',
  MEDICAL_INSIGHT: 'Medical_Insight_vod__c',
  MEDICAL_INQUIRY: 'Medical_Inquiry_vod__c',
  TASK: 'Task',
};

const MAX_DURATION_VOD_C = 20160

interface FIELD_HANDLER {
  [table: string]: {
    [field: string]:
      (item: CustomFieldDefinition,
       formSetting: FormSettings, allFormSettings: FormSettings[]) => CustomFieldDefinition[]
  }
}

const FIELD_HANDLER_MAP: FIELD_HANDLER = {
  [TABLES.TASK]: {
    'ActivityDate': handleTaskActivityDate,
  },
  [TABLES.CALL]: {
    'Duration_vod__c': handleCallDuration,
  },
  [TABLES.CALL_DISCUSSION]: {
    'Product_vod__c': handleCallDiscussionProduct,
    'Product_Strategy_vod__c': handleCallDiscussionProductStrategy,
    'Product_Tactic_vod__c': handleCallDiscussionProductTactic,
  },
  [TABLES.MEDICAL_DISCUSSION]: {
    'Product_vod__c': handleMedicalDiscussionProduct,
  },
  [TABLES.MEDICAL_INSIGHT]: {
    'Account_vod__c': handleCRMAccountSelect,
  },
  [TABLES.MEDICAL_INQUIRY]: {
    'Account_vod__c': handleCRMAccountSelect,
  },
}

// ** TRANSLATE FORM UTILS ** //
// THIS FUNCTION WILL CHECK SPECIFIC ITEMS AND, IF NECESSARY, WILL ADD ADDITIONAL SETTINGS
export function checkProcessItem
(items: CustomFieldDefinition[], formSetting: FormSettings, allFormSettings: FormSettings[]): CustomFieldDefinition[] {
  return items.reduce<CustomFieldDefinition[]>((acc, item) => {
    const fieldHandler = FIELD_HANDLER_MAP[formSetting.apiName]?.[item.id];
    if (fieldHandler) {
      const customFields = fieldHandler(item, formSetting, allFormSettings);
      acc.push(...customFields);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
}

function handleCRMAccountSelect(item: CustomFieldDefinition): CustomFieldDefinition[] {
  return [{
    ...item,
    controlType: ControlType.CRMACCOUNTSEARCHER,
    fieldType: FieldDataType.CATEGORICAL,
  }]
}

function handleTaskActivityDate(item: CustomFieldDefinition): CustomFieldDefinition[] {
  return [{
    ...item,
    dateRestriction: DateRestriction.ONLY_FUTURE,
  }];
}

function handleCallDuration(item: CustomFieldDefinition): CustomFieldDefinition[] {
  return [{
    ...item,
    maxLength: MAX_DURATION_VOD_C,
  }];
}

// HANDLES CALL_DISCUSSION PRODUCTS (SHOULD DEPEND ON SELECTED CALL_DETAIL PRODUCT)
function handleCallDiscussionProduct(
  item: CustomFieldDefinition,
  formSetting: FormSettings,
  allFormSettings: FormSettings[]): CustomFieldDefinition[] {
  const callDetailSetting = allFormSettings.find(({ apiName }) => apiName === TABLES.CALL_DETAIL);

  if (!callDetailSetting) {
    throw Error('Call Detail Settings not found');
  }

  const productsValues = getCallDetailsProductValues(callDetailSetting);

  return [{
    ...item,
    dependentFieldId: VEEVA_FIELDS_PLACEHOLDER.CALL_DETAIL,
    fieldValueDefinitions: productsValues.map((product) => ({
      ...product,
      dependentValueIds: [product.id],
    })),
  }, getProductMapPlaceholderField(item)]
}

// HANDLES CALL_DISCUSSION PROD STRATEGY (SHOULD DEPEND ON SELECTED PRODUCT/PLAN)
function handleCallDiscussionProductStrategy(
  item: CustomFieldDefinition,
  formSetting: FormSettings): CustomFieldDefinition[] {
  const { Product_Strategy_vod__c: strategies, Product_Plan_vod__c: plans } = formSetting.lookups;

  function handleStrategyValue
  (acc: CustomFieldValueDefinition[], value: CustomFieldValueDefinition): CustomFieldValueDefinition[] {
    const strategy = strategies.records.find((record) => record.fields.Id === value.id);
    const planOfStrategy = plans.records.find((record) =>
      record.fields.Id === strategy?.fields.Product_Plan_vod__c);

    if (planOfStrategy) {
      acc.push({
        ...value,
        label: `${planOfStrategy.fields.Name} | ${value.label}`,
        dependentValueIds: [`${planOfStrategy.fields.Product_vod__c}.${planOfStrategy.fields.Detail_Group_vod__c}`],
      });
    }

    return acc;
  }

  const fieldValueDefinitions =
    item.fieldValueDefinitions.reduce<CustomFieldValueDefinition[]>(handleStrategyValue, []);

  return [{
    ...item,
    dependentFieldId: `${formSetting.apiName}_Product_vod__c`,
    fieldValueDefinitions: orderArray(fieldValueDefinitions, ['label']),
  }];
}

// HANDLES CALL_DISCUSSION PROD TACTIC (SHOULD DEPEND ON SELECTED STRATEGY)
function handleCallDiscussionProductTactic(
  item: CustomFieldDefinition,
  formSetting: FormSettings): CustomFieldDefinition[] {
  const { Product_Strategy_vod__c: strategies, Product_Tactic_vod__c: tactics } = formSetting.lookups;

  function handleTacticValue
  (acc: CustomFieldValueDefinition[], value: CustomFieldValueDefinition): CustomFieldValueDefinition[] {
    const tactic = tactics.records.find((record) => record.fields.Id === value.id);
    const strategyOfTactic = strategies.records.find((record) =>
      record.fields.Id === tactic?.fields.Product_Strategy_vod__c);

    if (strategyOfTactic) {
      acc.push({
        ...value,
        dependentValueIds: [strategyOfTactic.fields.Id],
      });
    }

    return acc;
  }

  const fieldValueDefinitions = item.fieldValueDefinitions.reduce<CustomFieldValueDefinition[]>(handleTacticValue, []);

  return [{
    ...item,
    dependentFieldId: `${formSetting.apiName}_Product_Strategy_vod__c`,
    fieldValueDefinitions: orderArray(fieldValueDefinitions, ['label']),
  }]
}

// HANDLES PRODUCT_MAP FOR PRODUCTS OF MEDICAL DISCUSSION
function handleMedicalDiscussionProduct
(item: CustomFieldDefinition, formSetting: FormSettings): CustomFieldDefinition[] {
  // FIRST CHECK IF THERE ARE FIELDS THAT DEPEND ON 'zvod_Product_Map_vod__c'
  const areFieldsDependingOnProductMap = formSetting?.objectInfos.dependentFields.zvod_Product_Map_vod__c;

  if (!areFieldsDependingOnProductMap) {
    logger.veevaTranslatorUtil.log('No items found depending on ProductMap');
    return [item];
  }

  return [{ ...item }, getProductMapPlaceholderField(item)]
}

// DETERMINES WHICH OBEJCTS SHOULDN'T BE SENT WITHIN THE PAYLOAD
export function getIdsOfNonUpdateableObjects(mainCrmValues: FormValuesType, meetingORM: MeetingORM): string[] {
  const idsToIgnore: string[] = [];
  const CRMSettings: CRMIntegrationSession['authInformation'] | undefined = new CRMUtil().getAuthInformation();

  Object.entries(mainCrmValues).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      switch (key) {
        case TABLES.TASK: {
          value.forEach((task) => {
            // GETS ITS PREVIOUS SAVED VALUE AND VERIFIES IF IT CAN BE UPDATED (ITS OWNER IS THE SAME AS THE LOGGED USER)
            const referenceTask = meetingORM.model.crmRecord?.crmCustomValues
              .find(({ fieldId }) => fieldId === TABLES.TASK)?.objectRecords?.find(({ id }) => id === task.id);
            const taskOwnerId = referenceTask?.values.find(({ fieldId }) => fieldId === 'Task_OwnerId')?.values;

            // IF THE TASK CAN'T BE UPDATED (HAS A DIFFERENT OWNER), IT GETS SKIPPED
            if (task.externalId && referenceTask && !taskOwnerId?.includes(CRMSettings?.userInfo.id || '')) {
              idsToIgnore.push(task.id);
            }
          });
          break;
        }
        default:
      }
    }
  });

  return idsToIgnore;
}

// THE REASON OF THIS FIELD IS THAT THERE ARE OTHER FIELDS THAT HAVE THIS ONE AS
// DEPENDENCY (BUT THIS ONE SHOULDN'T BE DISPLAYED ON THE FORM). IT'S A "HACK" VEEVA USES
// TO HAVE PICKLIST DEPENDENCIES OVER NON PICKLIST COMPONENTS (LOOKUPS)
function getProductMapPlaceholderField(item: CustomFieldDefinition): CustomFieldDefinition {
  return {
    ...item,
    order: 1,
    required: false,
    settings: {
      fieldPosition: FieldPosition.LEFT,
    },
    dependentFieldId: undefined,
    fieldValueDefinitions: item.fieldValueDefinitions.map((value) => ({
      ...value,
      id: value.label || '',
      value: value.label || '',
    })),
    fieldType: FieldDataType.MULTICATEGORICAL,
    id: 'zvod_Product_Map_vod__c',
    displayOnParentSelection: true,
    fieldName: 'zvod_Product_Map_vod__c',
    fieldLabel: 'zvod_Product_Map_vod__c',
  }
}

//* PRE-PROCESS FORM FUNCTINOS *//
// RECEIVES ATTENDEES, MAIN RECORD AND ADDS TO EACH ONE THEIR OWN SET OF CHILD OBJECTS
export function veevaPreProcessFormAttendees(
  crmValues: FormValuesType,
  meetingORM: MeetingORM,
  attendees: ObjectWithId[]) {
  // 1. GETS THE OBJECT RECORDS FROM THE MAIN CRM RECORD
  const crmObjects: {[fieldKey: string]: ObjectWithId[]} = Object.entries(crmValues).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length && isArrayOfObjects(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (Object.keys(crmObjects).length) {
    // 2. TO EACH ATTENDEE, ADDS THEIR OWN OBJECT RECORDS WITH THEIR OWN IDS
    return attendees.map((attendee) => {
      if (!attendee.crmAccountId) {
        return attendee;
      }
      const attendeeInDB = meetingORM.model.attendees.find(({ id }) => id === attendee.id);

      const attendeeCrmValues: {[fieldKey: string]: ObjectWithId[]} = {};
      Object.entries(crmObjects).forEach(([key, objects]) => {
        const idsUsed: string[] = [];
        attendeeCrmValues[key] = objects.reduce<ObjectWithId[]>((acc, object) => {
          if (!attendeeInDB) {
            // IF IT'S A NEW ATTENDEE, CREATE THE OBJECT WITH A NEW ID
            acc.push({
              ...object,
              externalId: undefined,
              id: uuid(),
            });
          } else {
            let isRecordAdded = false;
            // OTHERWISE, USE THE ID OF AN EXISTING ONE
            attendeeInDB?.crmRecord?.crmCustomValues.forEach((customValue) => {
              if (customValue.fieldId === key) {
                customValue.objectRecords?.forEach((objectRecord) => {
                  if (objectRecord.syncStatus !== CrmSyncStatus.DELETED &&
                    objectRecord.status !== ObjectRecordStatus.REMOVED &&
                    !idsUsed.includes(objectRecord.id) && !isRecordAdded) {
                    isRecordAdded = true;
                    idsUsed.push(objectRecord.id);
                    acc.push({
                      ...object,
                      id: objectRecord.id,
                      externalId: objectRecord.externalId,
                    });
                  }
                });
              }
            });
            // THIS MEANS IT'S A NEW OBJECT
            if (!isRecordAdded) {
              acc.push({
                ...object,
                externalId: undefined,
                id: uuid(),
              });
            }
          }

          return acc;
        }, [])
      });

      return {
        ...attendee,
        crmValues: attendeeCrmValues,
      };
    });
  }

  return attendees;
}

export function veevaPreProcessCRMValues(
  crmValues: FormValuesType,
  meetingORM: MeetingORM): FormValuesType {
  if (crmValues[VEEVA_FIELDS_PLACEHOLDER.CALL_DETAIL]) {
    crmValues = handleProcessCallDetails(crmValues, meetingORM);
  }

  return crmValues;
}

// THIS FUNCTION GETS THE SELECTED PRODUCTS AND TRANSFORM THEM INTO CALL_DETAIL OBJECT
function handleProcessCallDetails(
  crmValues: FormValuesType,
  meetingORM: MeetingORM): FormValuesType {
  const selectedProducts = crmValues[VEEVA_FIELDS_PLACEHOLDER.CALL_DETAIL];
  const existingCallDetails = meetingORM.model.crmRecord?.crmCustomValues
    .find((customValue) => customValue.fieldId === TABLES.CALL_DETAIL)?.objectRecords || [];

  if (Array.isArray(selectedProducts) && selectedProducts.length) {
    crmValues[TABLES.CALL_DETAIL] = selectedProducts.map<ObjectWithId>((product, idx) => {
      const [productId, groupId] = product.split('.');
      // CHECKS IF THE PRODUCT ALREADY EXISTS TO USE THE ALREADY EXISTING ID
      const existingCallDetail = existingCallDetails.find((customValue) => {
        const existingProductId = customValue.values
          .find(({ fieldId }) => fieldId === 'Product_vod__c')?.values[0];
        const existingGroupId = customValue.values
          .find(({ fieldId }) => fieldId === 'Detail_Group_vod__c')?.values[0];

        return existingProductId === productId && existingGroupId === groupId;
      });

      return {
        id: existingCallDetail ? existingCallDetail.id : uuid(),
        externalId: existingCallDetail ? existingCallDetail.externalId : '',
        status: OBJECT_RECORD_STATUS.ACTIVE,
        Detail_Group_vod__c: groupId,
        Detail_Priority_vod__c: (idx + 1).toString(),
        Product_vod__c: productId,
        Type_vod__c: 'Paper_Detail_vod',
      }
    });
  }

  return crmValues;
}
