import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import {
  Collapsible,
  DNABox,
  DNAButton, DNACard,
  DNAText,
  Icon,
  Iffy, luxColors,
} from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { SortableItem, SortableItemProps } from '../DnD/DnDWrapper';

const styles = StyleSheet.create({
  iconStyle: {
    color: luxColors.contentText.quaternary,
    height: 20,
    paddingLeft: 5,
    width: 20,
  },
  wrapper: {
    paddingHorizontal: 18,
    paddingVertical: 16,
  },
  content: {
    borderTopColor: colors['color-gray-80'],
    borderTopWidth: 1,
  },
  DNACard: {
    minHeight: 'fit-content',
    borderRadius: 4,
  },
  selected: {
    minHeight: 'fit-content',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: colors['color-primary-500'],
  },
});

enum ContentPaddingOptions {
  default,
  none
}
type ContentPaddingOption = keyof typeof ContentPaddingOptions
type ContentPaddingConfigs = Record<ContentPaddingOption, ViewStyle|undefined>

const contentPaddingConfigs:ContentPaddingConfigs = {
  none: undefined,
  default: styles.wrapper,
}

enum VariantOptions {
  default = 'default',
  publisherReadonly = 'publisherReadonly',
  filters = 'filters'
}

type VariantOption = keyof typeof VariantOptions

export interface CollapsibleCardProps {
  collapsedDefault?: boolean;
  headerTitle: React.ReactElement | string;
  popoverInfoText?: string;
  numberOfTitleLines?: number;
  isCollapsed?: boolean;
  selected?: boolean;
  variant?: VariantOption;
  collapseRight?: boolean;
  contentPadding?: ContentPaddingOption,
  onToggleChanged?: (isCollapsed: boolean) => void;
  sortableHeader?: SortableItemProps
  cardStyle?: StyleProp<ViewStyle>,
}

const variantHeaderStyle = {
  default: styles.wrapper,
  publisherReadonly: { ...styles.wrapper, paddingVertical: 5, paddingHorizontal: 8 },
  filters: [styles.wrapper, { backgroundColor: colors['color-gray-10'] }],
}

const CollapsibleCard: React.FC<PropsWithChildren<CollapsibleCardProps>> = ((props) => {
  const {
    collapsedDefault,
    headerTitle,
    popoverInfoText,
    children,
    numberOfTitleLines,
    selected,
    variant = VariantOptions.default,
    collapseRight,
    contentPadding = 'default',
    sortableHeader,
    cardStyle,
  } = props

  const currentContentPadding = contentPaddingConfigs[contentPadding]

  const [isCollapsed, setIsCollapsed] = useState<boolean>(!!collapsedDefault);
  const headerStyle = variantHeaderStyle[variant];

  function toggleCollapse(): void {
    setIsCollapsed(open => !open);
    props.onToggleChanged?.(!isCollapsed);
  }

  useEffect(() => {
    if (props.isCollapsed !== undefined) {
      setIsCollapsed(props.isCollapsed);
    }
  }
  , [props.isCollapsed]);

  const { actionContainer, childrenContent } = useMemo(() => {
    return React.Children
      .toArray(children)
      .reduce<{
        actionContainer?: React.ReactNode;
        childrenContent: React.ReactNode[];
      }>(
        (acc, child) => {
          if (React.isValidElement(child)) {
            /** TODO: This can now be refactored using an approach that is more TS friendly.
             * See the <DNAConditionalButton> component for reference */
            // @ts-expect-error
            const displayName = child.type?.displayName

            if (displayName === CollapsibleActionMenu.displayName)
            {
              acc.actionContainer = child
              return acc
            }
          }
          acc.childrenContent.push(child);
          return acc
        }, {
          actionContainer: null,
          childrenContent: [],
        },
      )
  }, [children],
  )

  const collapseButtonIcon = collapseRight
    ? !isCollapsed ? 'chevron-up' : 'chevron-down'
    : !isCollapsed ? 'chevron-down' : 'chevron-right'

  const collapseButton =
  (<DNAButton
    status="tertiary"
    appearance="ghost"
    rounded="full"
    padding="xs"
    iconLeft={collapseButtonIcon}
    onPress={toggleCollapse}
  />)

  const Container = useMemo(() => ({ children }) => (
    sortableHeader
      ? <SortableItem {...sortableHeader}>
        {children}
      </SortableItem>
      : <>{children}</>
  ), [sortableHeader])

  return (
    <DNACard
      testID="collapsed-header-container"
      style={selected ? styles.selected : cardStyle || styles.DNACard}
      appearance="outline"
    >

      { /* HEADER */}
      <Pressable onPress={toggleCollapse}>
        <Container>
          <DNABox
            style={headerStyle}
            alignY="center"
            fill
          >
            <DNABox
              alignY="center"
              fill
            >
              <Iffy is={!collapseRight}>
                {collapseButton}
              </Iffy>

              {typeof headerTitle === 'string' ? <DNABox fill><DNAText
                testID="custom-deck-pool-group-title"
                b1
                bold
                numberOfLines={numberOfTitleLines ?? undefined}
              >
                {headerTitle}
              </DNAText></DNABox> : <DNABox fill>{headerTitle}</DNABox>}
            </DNABox>
            {/* TODO: find another way to add the actionComponent */}
            <Iffy is={popoverInfoText}>
              <DNAPopover >
                <DNAPopover.Anchor>
                  <Icon style={styles.iconStyle} name="help-circle-outline" />
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText status="basic">{popoverInfoText}</DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </Iffy>
            {actionContainer}
            <Iffy is={collapseRight}>
              {collapseButton}
            </Iffy>
          </DNABox>
        </Container>
      </Pressable>

      <Collapsible collapsed={isCollapsed} >
        { /* CONTENT */ }
        <DNABox
          appearance="col"
          spacing="md"
          style={[currentContentPadding, styles.content]}
        >
          <View>{childrenContent}</View>
        </DNABox>
      </Collapsible>
    </DNACard>
  )
})

/** TODO: Evaluate the necessity of this component in
 * https://alucioinc.atlassian.net/browse/BEAC-3691
 */
export const CollapsibleActionMenu: React.FC<PropsWithChildren> = ({ children }) => <>{children}</>

export default CollapsibleCard;
