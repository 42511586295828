import { DNABox } from '@alucio/lux-ui';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import React, { useEffect, useMemo, useRef } from 'react'
import { HighlighterProvider } from 'src/components/Highlighter/HighlighterProvider';
import { useParams } from 'react-router';
import { BroadcastChannel } from 'broadcast-channel'
import {
  PopoutContentBCPayloads,
  PopoutContentBCTypes,
} from 'src/state/context/Meetings/Hooks/useMeetingsPopoutContentState'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import ContentProvider from 'src/state/context/ContentProvider/ContentProvider';
import MeetingsStateProvider from 'src/state/context/Meetings/MeetingsStateProvider';
import MeetingsContent from '../MeetingsContent';

interface ContentProps {
  meetingId: string
  enableMultipleIFrames: boolean
}

const Content: React.FC<ContentProps> = ({ meetingId, enableMultipleIFrames }) => {
  const enableNew3PC = useFeatureFlags('enableNew3PC');

  if (!enableNew3PC) {
    return (
      <DNABox fill style={{ backgroundColor: 'black' }}>
        <HighlighterProvider>
          <PlayerWrapper
            isFullWindow={true}
            meetingId={meetingId}
            mode="NON_INTERACTIVE"
            isFromMeetings={true}
            highlighterVisible
            enableMultipleIFramesFeatureFlag={enableMultipleIFrames}
          />
        </HighlighterProvider>
      </DNABox>
    );
  }

  return (
    <ContentProvider meetingId={meetingId}>
      <MeetingsStateProvider meetingId={meetingId}>
        <MeetingsContent />
      </MeetingsStateProvider>
    </ContentProvider>
  );
};

const PopoutContent: React.FC = () => {
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>()
  const { meetingId } = useParams<{ meetingId: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const enableMultipleIFrames = useMemo(() => urlParams.get('enableMultipleIFrames') === 'true', []);

  const handleInit = () => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('MEETING_CONTENT');
    if (!enableNew3PC) {
      broadcastChannel.current.postMessage({ type: PopoutContentBCTypes.popoutWindowLoaded, meetingId });
    }

    // CLEANS THE URL TO REMOVE THE MEETING_ID //
    const newUrl = window.location.href.split(`/${meetingId}`)[0];
    window.history.pushState({}, document.title, newUrl);
    if (!meetingId) {
      window.close();
    }
    return () => {
      broadcastChannel.current?.close()
    }
  }

  useEffect(handleInit, [])

  return meetingId
    ? <Content meetingId={meetingId} enableMultipleIFrames={enableMultipleIFrames}/>
    : null
}

export default PopoutContent
