import {
  AssociatedFile,
  AttachedFile,
  CustomDeck,
  CustomDeckGroup,
  CustomDeckPage,
  CustomFormRecord,
  CustomFieldValueDefinition,
  CustomFieldDefinition,
  ContentShare,
  Document,
  DocumentStatus,
  DocumentVersion,
  DocumentPermission as SchemaDocumentPermission,
  DocumentIntegration,
  DocumentVersionIntegration,
  EmailTemplate,
  Folder,
  FolderItem,
  FieldConfig,
  LabelValue,
  Hub,
  IntegrationType,
  Meeting,
  MeetingFieldDefinition,
  MeetingFieldValueDefinition,
  Notation,
  ObjectRecordStatus,
  Page,
  PageGroup,
  PermissionType,
  SharePermission,
  Tenant,
  User,
  UserNotations,
} from '@alucio/aws-beacon-amplify/src/models';
import { AlucioAuthHeaders } from '@alucio/core';
import { CognitoUser } from 'src/models/User';
import { CRMAccount, CRMAddress } from 'src/classes/CRM/CRMIndexedDBTypes';
import { VERSION_UPDATE_STATUS } from './types';
import { FormValuesType } from '../components/CustomFields/ComposableForm';

// [TODO-297]: We should make extendable interfaces for easier type composition
export enum ORMTypes {
  ASSOCIATED_FILE = 'ASSOCIATED_FILE',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_VERSION = 'DOCUMENT_VERSION',
  FOLDER = 'FOLDER',
  FOLDER_ITEM = 'FOLDER_ITEM',
  CUSTOM_DECK = 'CUSTOM_DECK',
  CUSTOM_FORM_RECORD = 'CUSTOM_FORM_RECORD',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  PAGE = 'PAGE',
  PAGE_GROUP = 'PAGE_GROUP',
  MEETING = 'MEETING',
  SHARE_PERMISSION = 'SHARE_PERMISSION',
  USER = 'USER',
  CRM_ADDRESS = 'CRM_ADDRESS',
  CRM_ACCOUNT = 'CRM_ACCOUNT',
  HUB = 'HUB',
  CONTENT_SHARE = 'CONTENT_SHARE',
}

export const getAssociatedFileTitle = (af: AssociatedFileORM) => {
  return af.model.type === 'ATTACHED_FILE'
    ? (af.file as AttachedFile)?.title
    : af.relations.latestUsableDocumentVersion?.title
}

export const getAssociatedFileType = (af: AssociatedFileORM) => {
  return af.model.type === 'ATTACHED_FILE'
    ? (af.file as AttachedFile)?.type
    : af.relations.latestUsableDocumentVersion?.type
}

export interface AssociatedFileORM {
  model: AssociatedFile,
  type: ORMTypes.ASSOCIATED_FILE,
  meta: {
    canBeSharedByMSL: boolean,
  },
  relations: {
    latestUsableDocumentVersion?: DocumentVersion,
  },
  // It's possible that a DocVer may be updated BEFORE the AttachedFile record comes in
  // We cannot guarantee the order of subscribtions received so it's safer to assume
  // that the file itself may be undefined
  // [TODO] - Consider adding types to the file object for easier TS inference
  file?: Document | AttachedFile,
}

export interface UserORM {
  model: User,
  type: ORMTypes.USER,
  meta: {
    authProfile?: CognitoUser,
    formattedName?: string,
    customFilterValues: {
      defaultFilters: CustomFieldValuesMap,
      lockedFilters: CustomFieldValuesMap,
    },
    relations?: {
      tenant?: Tenant,
    },
  }
}

export interface FormattedDocumentNotations {
  [pageId: string]: Notation[],
}

export interface FormattedCustomDeckNotations {
  [pageId: string]: Notation[],
}

export interface FormattedNotations {
  documentLevel: FormattedDocumentNotations,
  customDeckLevel: FormattedCustomDeckNotations,
}

export interface CustomDeckORM {
  model: CustomDeck,
  type: ORMTypes.CUSTOM_DECK,
  meta: {
    formattedNotations: FormattedNotations,
    assets: {
      thumbnailKey?: string,
      isContentCached: boolean,
    },
    title?: string,
    version: {
      updateStatus: VERSION_UPDATE_STATUS,
      requiresReview: boolean,
      autoUpdateUnacknowledged: boolean,
      withinGracePeriod: boolean,
    },
    permissions: {
      isCollaborator: boolean,
      MSLPresent: boolean,
      MSLShare: boolean,
      MSLDownload: boolean,
    }
    containsWebDocs: boolean,
    containsVideoDoc: boolean,
    containsHTMLDocs: boolean,
    customDeckGroups: CustomDeckGroupORM[],
    hasExternalDependency: boolean,
  },
  relations: {
    userNotations?: UserNotations,
  },
}

export interface CustomDeckGroupORM {
  isGroup: boolean,
  model: CustomDeckGroup,
  pages: {
    model: CustomDeckPage,
    documentVersionORM: DocumentVersionORM,
    page: Page,
  }[],
  meta: {
    version: {
      updateStatus: VERSION_UPDATE_STATUS,
    },
  },
}

export interface DocumentORM {
  model: Document,
  type: ORMTypes.DOCUMENT,
  relations: {
    documentVersions: DocumentVersionORM[],
    tenant: Tenant, // NOTE: No ORM'd version yet
    version: {
      latestUsableDocumentVersionORM: DocumentVersionORM,
      latestDocumentVersionORM: DocumentVersionORM,
      latestPublishedDocumentVersionORM: DocumentVersionORM | undefined,
      cachedDocumentVersionORM: DocumentVersionORM | undefined,
    },
  },
  meta: {
    assets: {
      thumbnailKey?: string,
      fileURL?: string,
      getAuthHeaders: () => Promise<AlucioAuthHeaders>,
    },
    bookmark: {
      isBookmarked: boolean,
      createdAt?: string,
    },
    customValues: {
      areRequiredFieldsCompleted: boolean,
      configsMap: CustomFieldValuesMap,
    },
    tenantFields: {
      labelCompletion: boolean,
      valuesMap: {
        [key: string]: LabelValue['value'][],
      },
      configsMap: {
        [key: string]: FieldConfig,
      },
    },
    integration: {
      integrationType?: IntegrationType | keyof typeof IntegrationType,
      integration?: DocumentIntegration,
      source?: string,
    },
    permissions: DocumentPermission,
    hasUnpublishedVersion: boolean,
    sealedStatus?: (
      | DocumentStatus.ARCHIVED
      | DocumentStatus.REVOKED
      | DocumentStatus.DELETED
      | 'ARCHIVED'
      | 'REVOKED'
      | 'DELETED'
    ),
  },
}

export interface DocumentVersionORM {
  model: DocumentVersion,
  type: ORMTypes.DOCUMENT_VERSION,
  relations: {
    documentORM: DocumentORM,
    associatedFiles: AssociatedFileORM[],
    pages: PageORM[],
    pageGroups: PageGroupORM[],
    userNotations?: UserNotations,
  },
  meta: {
    assets: {
      thumbnailKey?: string,
      fileURL?: string,
      getAuthHeaders: () => Promise<AlucioAuthHeaders>,
      contentKey?: string,
      isContentCached: boolean,
      isThumbnailCached: boolean,
      accessToken?: string,
    },
    allPages: Page[],
    version: {
      semVerLabel: string,
      isLatestPublished: boolean,
      updateStatus: VERSION_UPDATE_STATUS,
    },
    integration: {
      integrationType?: IntegrationType | keyof typeof IntegrationType,
      integration?: DocumentVersionIntegration,
      source?: string,
    },
    permissions: DocumentPermission,
    customValues: {
      areRequiredFieldsCompleted: boolean,
      configsMap: CustomFieldValuesMap,
    },
    tenantFields: {
      labelCompletion: boolean,
      valuesMap: {
        [key: string]: LabelValue['value'][],
      },
      configsMap: {
        [key: string]: FieldConfig,
      },
    },
    // [TODO] - These probably fit better under a category (presentation or something similar)
    sealedStatus?: DocumentORM['meta']['sealedStatus'],
    badges: CustomFieldValueDefinition[],
    watermarkText?: string,
    schedule: {
      publish: {
        isScheduled: boolean,
        scheduledAt?: string,
      },
    },
    srcFileDownloadURL: string,
  },
}

export interface DocumentPermission {
  bookmark: boolean,
  addToFolder: boolean,
  MSLShare: boolean,
  MSLPresent: boolean,
  MSLDownload: boolean,
  MSLSelectSlides: boolean,
  MSLNonModifiable: boolean,
  MSLExternalNotatable: boolean,
  fieldLevel: Record<keyof SchemaDocumentPermission, boolean>,
}

export interface EmailTemplateORM {
  model: EmailTemplate,
  relations: {
    associatedFiles: AssociatedFileORM[],
  },
  meta: {
    customFilterValues: CustomFieldValuesMap,
  },
  type: ORMTypes.EMAIL_TEMPLATE,
}

export interface CustomFormRecordORM {
  model: CustomFormRecord,
  type: ORMTypes.CUSTOM_FORM_RECORD,
  meta: {
    formValues: FormValuesType,
  },
}

export interface FolderORM {
  model: Folder,
  type: ORMTypes.FOLDER,
  relations: {
    parentFolderORM: FolderORM | null,
    items: FolderItemORM[],
    ownerORM?: UserORM,
    sharePermissions: SharePermissionORM[],
  },
  meta: {
    isSharedWithTheUser?: boolean,
    hasExternalDependencies?: boolean,
    isSharedFolder?: boolean,
    itemCount: number,
    folderCount: number,
    version: {
      containsAutoUpdatedItem: boolean,
      containsOutdatedDocVer: boolean,
      containsPendingReviewItem: boolean,
    },
    permission?: PermissionType,
  },
}

export interface SharePermissionORM {
  model: SharePermission,
  type: ORMTypes.SHARE_PERMISSION,
  meta: {
    isValidPermission: boolean,
    customFilterValues: CustomFieldValuesMap,
  }
}

export interface FolderItemORM {
  model: FolderItem,
  type: ORMTypes.FOLDER_ITEM,
  meta: {
    assets: {
      // [TODO-2126] - This is currently not being used anywhere, but might be good in the future
      //               to have access to it at this level
      thumbnailKey?: string,
    },
    title: string,
    hasAutoUpdatedItem: boolean,
    hasOutdatedItem: boolean,
    isModified?: boolean,
  },
  relations: {
    itemORM: DocumentVersionORM | FolderORM | CustomDeckORM,
    parentORM?: FolderORM,
  },
}

export interface PageORM {
  model: Page,
  type: ORMTypes.PAGE,
  relations: {
    documentVersionORM: DocumentVersionORM,
    pageGroupORM?: PageGroupORM,
    linkedSlides?: PageORM[],
  },
}

export interface PageGroupORM {
  model: PageGroup,
  type: ORMTypes.PAGE_GROUP,
  meta: {
    isRequired: boolean,
  },
  relations: {
    pages: PageORM[],
    documentVersionORM: DocumentVersionORM,
  },
}

// [NOTE] - PascalCase, maybe remove type suffix
export type CurrentValuesMap = {
  [fieldId: string]: {
    // Reference back to parent
    field: MeetingFieldDefinition,
    // STRING | DATE types
    value?: string,

    // CATEGORICAL | MULTICATEGORICAL
    // When updating object, we can map this back to FieldValue type
    // [NOTE] - This is where the form's default values will get its values
    fieldValues?: MeetingFieldValueDefinition[],
    // [TODO-2780] - This could also just be an array of strings, and we get the FieldValueDef
    //          from customFieldValueDefinitionsMap
  },
}

export type CustomFieldValue = {
  field: CustomFieldDefinition,
  displayValues: string[],
  values: string[],
  valuesDefinition?: CustomFieldValueDefinition[],
  objectValues?: CustomObjectFieldValue[],
}

export type CustomObjectFieldValue = {
  id: string,
  externalId?: string,
  status: ObjectRecordStatus,
  customFieldValues: CustomFieldValuesMap,
}

export type CustomFieldValuesMap = {
  [fieldId: string]: CustomFieldValue,
}

export interface MeetingORM {
  model: Meeting,
  type: ORMTypes.MEETING,
  // [TODO-2780] - Revisit this before release
  meta: {
    customValues: {
      areRequiredFieldsCompleted: boolean,
      configsMap: CustomFieldValuesMap,
    },
    fields: {
      // Maybe add the beacon config here for convenience
      staticFields: any[],
      // This will define the order - comes from tenant
      customFields: MeetingFieldDefinition[],

      // [TODO-2780] - This could also be organized under a specific field
      //               but for now, just have all fieldValues def at the top level
      customFieldValueDefinitionsMap: Record<string, MeetingFieldValueDefinition>,
      // This can be a map with ids pointing to the field id
      customValues: CurrentValuesMap,
    },
  },
}

export interface CRMAccountORM {
  model: CRMAccount,
  type: ORMTypes.CRM_ACCOUNT,
  meta: {
    formattedName: string,
    formattedLongName?: string[],
  },
  relations: {
    addresses: CRMAddressORM[],
  },
}

export interface CRMAddressORM {
  model: CRMAddress,
  type: ORMTypes.CRM_ADDRESS,
  meta: {
    formattedName: string,
    formattedLongName?: string[],
  },
}

export interface HubORM {
  model: Hub,
  type: ORMTypes.HUB,
  meta: {
  },
  // relations: {
  //   contentShare: ContentShareORM[]
  // },
}

export interface ContentShareORM {
  model: ContentShare,
  type: ORMTypes.CONTENT_SHARE,
}
