import React from 'react';
import { DNABox, Iffy } from '@alucio/lux-ui';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import useVirtualnActionBarComponents from './useVirtualActionBarComponents';

const VirtualActionBar: React.FC = () => {
  const {
    isMainPlayer,
  } = useMeetingsState();

  const {
    myContentButton,
    presentationsButton,
    slidesButton,
    meetingDetailsButton,
    presentationNav,
    highlighterContextMenu,
    textSearchButton,
    sentimentsContextMenu,
    myNotesButton,
    changeToVirtualModeButton,
    focusPopoutWindowButton,
  } = useVirtualnActionBarComponents()

  return (
    <>
      {/* PRESENTER ACTION BAR */}
      <Iffy is={isMainPlayer}>
        <DNABox spacing="between" alignY="center" style={{ padding: 8 }}>
          {/* LEFT */}
          <DNABox spacing="sm">
            {myContentButton}
            {presentationsButton}
            {slidesButton}
            {meetingDetailsButton}
          </DNABox>
          {/* MIDDLE */}
          <DNABox>
            {presentationNav}
          </DNABox>
          {/* RIGHT */}
          <DNABox spacing="sm">
            {highlighterContextMenu}
            {textSearchButton}
            {sentimentsContextMenu}
            {myNotesButton}
            {changeToVirtualModeButton}
          </DNABox>
        </DNABox>
      </Iffy>

      {/* FOCUS POPOUT WINDOW BUTTON */}
      <Iffy is={!isMainPlayer}>
        <DNABox
          style={{
            bottom: 5,
            right: 5,
            position: 'absolute',
          }}
        >
          {focusPopoutWindowButton}
        </DNABox>
      </Iffy>
    </>
  );
};

export default VirtualActionBar
