import React, { useEffect } from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAText, Iffy, Stack } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import SlideRollWrapper from 'src/screens/Meetings/SharedComponents/SlideRollWrapper';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import TextSearchPanel from 'src/components/TextSearchPanel/TextSearchPanel';
import { DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader';
import AllNotes from 'src/screens/Meetings/SharedComponents/Notes/AllNotes';
import Header from './Header';
import VirtualActionBar from './VirtualActionBar';

/** ANIMATION IMPORTS */
import LottieAnimation from 'react-lottie-player';
import { isWebHtmlOrVideoDocumentVersionORM } from 'src/types/typeguards';
const PopupHiddenAnimation = require('src/assets/lottie/shared-window-visibility-animation.json')

const S = StyleSheet.create({
  contentContainer: {
    backgroundColor: colors['color-gray-800'],
  },
  iframeContainer: {
    borderColor: colors['color-success-500'],
    borderWidth: 4,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  slideRollContainer: {
    borderRightWidth: 1,
    borderColor: '#383838',
  },
  invisibleSlideRollContainer: {
    width: 0,
    height: 0,
    display: 'none',
  },
  contentStack: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  hiddenStack: {
    width: 0,
    height: 0,
  },
  sidebarStackLayer: {
    height: '100%',
  },
  backgroundImage: {
    flex: 1,
  },
  overlayLayer: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
});

const VirtualContent = () => {
  const { activePresentation, isCustomDeck } = useContent();
  const {
    meetingORM,
    slideRollVisible,
    setSlideRollVisible,
    toggleSlideRollVisibility,
    textSearchVisible,
    setTextSearchVisible,
    popoutContentHidden,
    openNotes,
    isMainPlayer,
  } = useMeetingsState();

  useEffect(() => {
    const isWebHtmlOrVideo = isWebHtmlOrVideoDocumentVersionORM(activePresentation?.presentable?.orm)
    if (isWebHtmlOrVideo && slideRollVisible) setSlideRollVisible(false)
  }, [activePresentation])

  if (!meetingORM) return null;

  return (
    <DNABox fill appearance="col" style={S.contentContainer}>
      {/* HEADER */}
      <Header />
      <DNADivider variant="virtual" />
      {/* CONTENT */}
      <DNABox
        fill
        appearance="col"
        testID="presentation-content"
      >
        <Stack anchor="bottom" style={S.contentStack}>
          <Stack.Layer style={S.contentStack}>
            <DNABox fill style={S.iframeContainer}>
              {/* BACKGROUND IMAGE */}
              <Iffy is={!activePresentation && isMainPlayer}>
                <ImageBackground
                  source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
                  style={S.backgroundImage}
                />
              </Iffy>
              {/* IFRAME */}
              <Iffy is={activePresentation || !isMainPlayer}>
                <PlayerWrapper.Highlighter
                  isFullWindow
                  meetingId={meetingORM.model.id}
                  mode="INTERACTIVE"
                  lockAspectRatio={false}
                  isTabletMeetingMode
                  isFromMeetings={true}
                  highlighterVisible
                  analyticsEventType={DNALoaderEvents.MEETING}
                  isCustomDeck={isCustomDeck}
                />
              </Iffy>
              {/* SPEAKER/MY NOTES */}
              <AllNotes openNotes={openNotes}/>
            </DNABox>
          </Stack.Layer>
          {/* CONTENT NOT VISIBLE OVERLAY */}
          <Stack.Layer style={popoutContentHidden ? S.contentStack : S.hiddenStack}>
            <Iffy is={popoutContentHidden}>
              <DNABox
                fill
                style={S.overlayLayer}
                alignX="center"
                alignY="center"
                appearance="col"
                spacing="lg"
              >
                <DNAText h1 bold status="basic">Adjust windows so shared window is visible</DNAText>
                <LottieAnimation
                  play
                  animationData={PopupHiddenAnimation}
                />
              </DNABox>
            </Iffy>
          </Stack.Layer>

          {/* SLIDE ROLL */}
          <Stack.Layer anchor="topLeft" style={S.sidebarStackLayer}>
            <Iffy is={activePresentation && isMainPlayer}>
              <DNABox fill style={[S.slideRollContainer, !slideRollVisible && S.invisibleSlideRollContainer]}>
                <SlideRollWrapper closeButtonOnPress={toggleSlideRollVisibility}/>
              </DNABox>
            </Iffy>
          </Stack.Layer>
          {/* TEXT SEARCH */}
          <Stack.Layer anchor="topLeft" style={S.sidebarStackLayer}>
            <DNABox fill>
              {textSearchVisible &&
                <TextSearchPanel
                  onClose={() => { setTextSearchVisible(false) }}
                  contentHookVariant="proxied"
                />
              }
            </DNABox>
          </Stack.Layer>
        </Stack>
      </DNABox>
      {/* ACTION BAR */}
      <VirtualActionBar />
    </DNABox>
  );
};

VirtualContent.displayName = 'VirtualContent';

export default VirtualContent;
