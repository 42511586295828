import React, { useMemo } from 'react'
import {
  ScrollView,
  StyleSheet,
} from 'react-native';
import {
  Iffy,
  DNABox,
  DNAText,
  DNADivider,
  InformationMessage,
  Spinner,
} from '@alucio/lux-ui'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { multiTypeSort } from 'src/state/redux/selector/common'

import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { useVersioningPanel } from './VersioningPanelContext';
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel'
import { FieldPosition, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { CustomFieldValue } from 'src/types/orms';
import { ReadonlyFields } from 'src/components/CustomFields/ReadonlyField'
import { INFO_MESSAGES } from 'src/state/machines/versioning/versioningConstants';

const styles = StyleSheet.create({
  LeftColumn: {
    maxWidth: 612,
  },
  ColumnDivider: {
    marginHorizontal: 24,
    maxWidth: 2,
  },
  ThumbnailBorder: {
    borderColor: colors['color-gray-100'],
    borderRadius: 2,
    borderWidth: 1,
  },
  mainWrapper: {
    flex: 1,
  },
  spinnerIcon: {
    marginLeft: 10,
    marginRight: 16,
  },
})

const titleProps: DNATextProps = {
  bold: true,
  status: 'dark',
  b1: true,
}

const DocumentInfoReadOnly: React.FC = () => {
  const { currentDocumentVersionORM, cond, meta } = useVersioningPanel()
  const { leftFieldValues, rightFieldValues } = useMemo(
    () => {
      const [leftValues, rightValues] = Object
        .entries(currentDocumentVersionORM.meta.customValues.configsMap)
        .reduce<[CustomFieldValue[], CustomFieldValue[]]>(
          (acc, [_, customFieldValue]) => {
            const [leftFields, rightFields] = acc

            customFieldValue.field.settings?.fieldPosition === FieldPosition.LEFT
              ? leftFields.push(customFieldValue)
              : rightFields.push(customFieldValue)

            return acc
          },
          [[], []],
        )

      const leftFieldValues = leftValues.sort((a, b) => multiTypeSort(a.field.order, b.field.order, { dir: 'asc' }))
      const rightFieldValues = rightValues.sort((a, b) => multiTypeSort(a.field.order, b.field.order, { dir: 'asc' }))

      return { leftFieldValues, rightFieldValues }
    }, [currentDocumentVersionORM.meta.customValues.configsMap])

  const isWebDoc = currentDocumentVersionORM.model.type === FileType.WEB
  const isVideo = currentDocumentVersionORM.model.type === FileType.MP4
  const isHTMLDoc = currentDocumentVersionORM.model.type === FileType.HTML
  const isNonDocumentFile = isWebDoc || isVideo || isHTMLDoc

  return (
    <ScrollView style={[styles.mainWrapper, S.tabContentContainer]}>
      <DNABox
        fill
        appearance="col"
        spacing="lg"
        childFill={2}
      >
        {/* INFORMATIONAL BANNER */}
        <Iffy is={cond.hasInfoMessage}>
          <InformationMessage
            leftIcon={cond.isProcessingNewVersion
              ? <DNABox style={styles.spinnerIcon}>
                <Spinner status="primary" size="sm" />
              </DNABox> : undefined}
            text={meta.infoMessage?.message}
            variance={meta.infoMessage?.status}
          />
        </Iffy>
        <Iffy is={cond.exceedsMaxOfflineSize}>
          <InformationMessage
            text={INFO_MESSAGES.FILE_TOO_BIG.message}
            variance={INFO_MESSAGES.FILE_TOO_BIG.status}
          />
        </Iffy>
        {/* DOCUMENT HEADER */}
        <DNABox alignY="end" spacing="md" childFill={1}>
          {/* DOCUMENT - THUMBNAIL */}
          <DNABox style={styles.ThumbnailBorder}>
            <DNADocumentThumbnail
              documentVersionORM={currentDocumentVersionORM}
              width={isNonDocumentFile ? 216 : 137}
              height={isNonDocumentFile ? 124 : 75}
              showProcessing={true}
            />
          </DNABox>

          {/* DOCUMENT - TITLE */}
          <DNABox appearance="col" spacing="md">
            <DNABox appearance="col" spacing="xs">
              <DNAText {...titleProps}>Title</DNAText>
              <DNAText status="flat" numberOfLines={2}>
                {currentDocumentVersionORM.model.title}
              </DNAText>
            </DNABox>
            <Iffy is={isWebDoc}>
              <DNABox appearance="col" spacing="xs">
                <DNAText {...titleProps}>Website URL</DNAText>
                <a
                  target="_blank"
                  href={currentDocumentVersionORM.model.contentURL}
                  style={{ color: '#44a2da', textUnderlineOffset: 2 }}
                >
                  <DNAText status="primary" numberOfLines={2}>{currentDocumentVersionORM.model.contentURL}</DNAText>
                </a>
                <DNAText p2 status="flatAlt">
                  You may want to periodically review this link to ensure it is not broken.
                </DNAText>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNABox>

        <ScrollView>
          <DNABox>
            {/* LEFT COLUMN */}
            <DNABox fill appearance="col">
              <ReadonlyFields customFieldValues={leftFieldValues}/>
            </DNABox>

            {/* COLUMN DIVIDER */}
            <DNADivider vertical style={styles.ColumnDivider} />

            {/* RIGHT COLUMN */}
            <DNABox fill appearance="col">
              <ReadonlyFields customFieldValues={rightFieldValues}/>
            </DNABox>
          </DNABox>
        </ScrollView>
      </DNABox>
    </ScrollView>
  )
}

export default DocumentInfoReadOnly;
