import React from 'react';
import useMyContentPanelSharedResources, { MyContentPanelProps } from '../../useMyContentPanelSharedResources';
import { DNABox, DNADivider } from '@alucio/lux-ui';
import Header from './Header';
import Content from './Content';
import { FolderItemORM, PageGroupORM } from 'src/types/orms';
import { StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { isPageGroupORM } from 'src/types/typeguards';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['brand2-900'],
    paddingBottom: 60,
  },
})

const MeetingsVariant: React.FC<MyContentPanelProps> = (props) => {
  const {
    selectedDocumentVersion,
    selectedTab,
    localSearchText,
    setSelectedTab,
    displayMode,
    setLocalSearchText,
    setSearchText,
    searchText,
    setSelectedDocumentVersion,
  } = useMyContentPanelSharedResources(props)
  const { togglePresentationControlsVisibility } = useMeetingsState()

  const onPresent = (
    index: number | undefined,
    presentableORM: FolderItemORM | PageGroupORM,
  ) => {
    /** TODO: Consider whether this would be better as useEffect in the context if
     * we want this to happen universally when we select a new presentableORM */
    togglePresentationControlsVisibility()
    if (isPageGroupORM(presentableORM)) {
      props.onSelectPresentableORM?.(presentableORM, index)
    } else {
      props.onSelectPresentableORM?.(presentableORM || selectedDocumentVersion, index);
    }
  }

  return (
    <DNABox appearance="col" fill style={styles.content}>
      <Header
        selectedTab={selectedTab}
        localSearchText={localSearchText}
        selectedDocument={selectedDocumentVersion?.relations.documentORM}
        onSelectTab={setSelectedTab}
        displayMode={displayMode}
        setLocalSearchText={setLocalSearchText}
        setSearchText={setSearchText}
        sessionId={props.sessionId}
      />
      <DNADivider variant="virtual" />
      {selectedTab &&
        <Content
          localSearchText={localSearchText}
          searchText={searchText}
          selectedTab={selectedTab}
          selectedDocumentVersion={selectedDocumentVersion}
          setSelectedDocumentVersion={setSelectedDocumentVersion}
          setSelectedPage={onPresent}
          displayMode={displayMode}
          sessionId={props.sessionId}
        />
      }
    </DNABox>
  )
}

export default MeetingsVariant
